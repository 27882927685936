import {Component, ElementRef, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CodaltComponent} from '../codalt.component';
import {Settings} from '../settings.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

declare var Microsoft: any;

@Component({
    selector: 'app-shape-map',
    templateUrl: './shape-map.component.html',
    styleUrls: ['./shape-map.component.scss']
})
export class ShapeMapComponent extends CodaltComponent implements OnInit {

    borden: Bord[];

    private timer;
    private map: any;
    private pushpinLayer: any;

    constructor(private elRef: ElementRef,
                private http: HttpClient,
                private confirmService: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        let tries = 0;
        this.timer = setInterval(() => {
            tries++;
            if (typeof Microsoft !== 'undefined') {
                this.loadMap();
                clearInterval(this.timer);
            }
            if (tries > 50) {
                clearInterval(this.timer);
                alert('Kaart kon niet worden geladen, controleer je internetverbinding');
            }
        }, 250);

    }

    private loadMap() {
        this.map = new Microsoft.Maps.Map(this.elRef.nativeElement.querySelector('#map'), {
            credentials: 'AvaQB1Fe_AeAII18YRy4SY0OzNnb05Js89lC1HOkUgyohnWeDlMfe5Jq7zJRI20g'
        });
        this.map.setView({
            center: new Microsoft.Maps.Location(52.21243206609494, 5.9656758300518495),
            zoom: 18
        });

        this.http.get<Bord[]>('assets/borden.json').subscribe(borden => {
            this.borden = borden;
            const pushpinsToDraw = [];
            this.borden.forEach(bord => {
                const pushpin = new Microsoft.Maps.Pushpin(
                    new Microsoft.Maps.Location(bord.grond_lat, bord.grond_lng),
                    {
                        icon: Settings.getMarker(
                            bord.grond_name,
                            bord.borden.join(' | '),
                            'E8B914',
                            'E8B914',
                            true
                        ),
                        anchor: new Microsoft.Maps.Point(32, 34),
                        data: bord,
                        id: bord.bord_fid
                    }
                );
                pushpinsToDraw.push(pushpin);

                Microsoft.Maps.Events.addHandler(pushpin, 'click', (e) => {
                    console.log(e);
                    console.log(bord);

                    let bordenImg = '';
                    bord.borden.forEach(b => {
                        let bb = b.toUpperCase();
                        if(bb.substring(1,2) === '0'){
                            bb = `${bb.substring(0,1)}${bb.substring(2,3)}`;
                        }
                        if(bb.substring(0,1) === 'J'){
                            bordenImg += `<img width="100" src="https://upload.wikimedia.org/wikipedia/commons/8/8a/Nederlands_verkeersbord_${bb.toUpperCase()}.svg" />`;
                        }
                        if(bb.substring(0,1) === 'L'){
                            bordenImg += `<img width="100" src="https://upload.wikimedia.org/wikipedia/commons/a/ac/Nederlands_verkeersbord_${bb.toUpperCase()}.svg" />`;
                        }
                        if(bb.substring(0,1) === 'C'){
                            bordenImg += `<img width="100" src="https://upload.wikimedia.org/wikipedia/commons/3/31/Nederlands_verkeersbord_${bb.toUpperCase()}.svg" />`;
                        }
                        if(bb.substring(0,1) === 'G'){
                            bordenImg += `<img width="100" src="https://upload.wikimedia.org/wikipedia/commons/0/0a/Nederlands_verkeersbord_${bb.toUpperCase()}.svg" />`;
                        }
                    });

                    this.confirmService.confirm(bord.bord_name, `
                    Bord: ${bord.bord_name}<br>
                    Bord FID: ${bord.bord_fid}<br>
                    Grond FID: ${bord.grond_fid}<br>
                    Grondpunt: ${bord.grond_fid}<br>
                    Borden: ${bord.borden}<br>
                    <div class="d-flex">${bordenImg}</div>
                    `);
                });
            });
            Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
                this.pushpinLayer = new Microsoft.Maps.Layer();
                this.pushpinLayer.add(pushpinsToDraw);
                this.map.layers.insert(this.pushpinLayer);
            });
        }, () => {

        });
    }


}

export class Bord {
    grond_name: string;
    grond_lng: number;
    grond_lat: number;
    grond_fid: string;
    bord_name: string;
    bord_lng: number
    bord_lat: number;
    bord_fid: string;
    borden: string[];
}
