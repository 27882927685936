import {LocalStorage} from './storage.class';
import {PushpinFilter} from './pushpin-filter';
import * as FileSaver from 'file-saver';
import {formatNumber} from '@angular/common';

export class Settings {

    public static readonly unauthorizedAllowedUrls = [
        'request-restore-password',
        'restore-password',
        'forgot-password',
        'invite',
        'login',
        'register',
        'document',
        'accept-work',
        'checklist-pdf',
        'borden-test',
        ''
    ];
    public static readonly BING_MAPS_API_KEY = 'AvaQB1Fe_AeAII18YRy4SY0OzNnb05Js89lC1HOkUgyohnWeDlMfe5Jq7zJRI20g';
    public static readonly DEFAULT_FILL_COLOR = 'rgba(103, 198, 221, 0.3)';
    public static readonly ZOOMLEVEL_TRESHOLD_FILLCOLOR = 12;
    public static readonly ZOOMLEVEL_TRESHOLD_HECTOMETRE = 12;
    public static readonly ZOOMLEVEL_TRESHOLD_HECTOMETRE_M = 15;
    public static mapCenter: any = {
        latitude: 52.319413,
        longitude: 4.576759
    };
    public static mapZoom = 1;
    public static readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public static readonly EXCEL_EXTENSION = '.xlsx';
    public static pushpinFilter = new PushpinFilter();
    private static readonly _LOCAL_API_ENDPOINT = 'http://vvr.localhost/api/';
    private static readonly _TEST_API_ENDPOINT = 'https://vvr-test.codalt.com/api/';
    private static readonly _PROD_API_ENDPOINT = 'https://app.vvr.nl/api/';
    public static API_ENDPOINT: string = Settings.isLocalHost() ? Settings._LOCAL_API_ENDPOINT :
        Settings.isTest() ? Settings._TEST_API_ENDPOINT : Settings._PROD_API_ENDPOINT;

    public static _mapSettings: any;
    public static readonly inspectionLockGrace = 168; //hours

    public static get mapSettings() {
        if (this._mapSettings) {
            return this._mapSettings;
        }
        let storageMapSettings = LocalStorage.getMapSettings();
        if (!storageMapSettings) {
            storageMapSettings = {
                'show_cancelled': false,
                'show_executed_month': true,
                'show_executed_year': false,
                'show_approved': true,
                'show_own': true
            };
        }
        return this._mapSettings = storageMapSettings;
    }

    public static set mapSettings(setSettings) {
        LocalStorage.setMapSettings(this._mapSettings = setSettings);
    }

    public static getHecto(way: string, hecto: number, letter: string) {
        return `<?xml version="1.0" encoding="UTF-8"?>
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <rect id="path-1" x="0" y="0" width="40" height="28" rx="1" fill="#006E34" stroke="#FFFFFF" stroke-width="2"></rect>
            <rect id="path-2" x="19" y="28" width="2" height="12" rx="1" fill="#000000"></rect>
            <text id="text-${way}${hecto}${letter}" font-family="Roboto" font-size="9" font-weight="700" fill="#FFFFFF">
            <tspan x="2" y="11">${way}</tspan>
            <tspan x="26" y="11">${(letter || '')}</tspan>
            <tspan x="2" y="23">${formatNumber(hecto / 10, 'nl', '1.1-1')}</tspan>
        </text>
        </defs>
        <g id="Pin-hecto" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
        <use fill="black" fill-opacity="1" xlink:href="#path-1"></use>
            <use fill="black" fill-opacity="1" xlink:href="#path-2"></use>
            </g>
            <g id="123" fill="#FFFFFF" fill-opacity="1">
            <use xlink:href="#text-${way}${hecto}${letter}"></use>
            </g>
            </g>
            </svg>`;
    }

    public static getMarker(identifier, number, color1, color2, roadStyle: boolean) {
        color1 = '#' + color1.toUpperCase();
        color2 = '#' + color2.toUpperCase();
        const satelliteIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="80px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="11" y="16" width="2" height="18" rx="1"></rect>
        <filter x="-375.0%" y="-41.7%" width="850.0%" height="183.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <radialGradient cx="33.972168%" cy="30.2246094%" fx="33.972168%" fy="30.2246094%" r="96.0639357%" id="radialGradient-${identifier}">
            <stop stop-color="${color1}" offset="0%"></stop>
            <stop stop-color="${color2}" offset="100%"></stop>
        </radialGradient>
        <circle id="path-4" cx="12" cy="14" r="8"></circle>
        <filter x="-46.9%" y="-46.9%" width="193.8%" height="193.8%" filterUnits="objectBoundingBox" id="filter-5">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <text id="text-${identifier}" font-family="Roboto-Black, Roboto" font-size="8" font-weight="700" fill="#FFFFFF">
            <tspan x="22" y="17">${number}</tspan>
        </text>
        <filter x="-60.7%" y="-72.2%" width="221.4%" height="288.9%" filterUnits="objectBoundingBox" id="filter-7">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Pin-sat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Oval">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
            <use fill="url(#radialGradient-${identifier})" fill-rule="evenodd" xlink:href="#path-4"></use>
        </g>
        <g id="123" fill="#FFFFFF" fill-opacity="1">
            <use filter="url(#filter-7)" xlink:href="#text-${identifier}"></use>
            <use xlink:href="#text-${identifier}"></use>
        </g>
    </g>
</svg>`;

        const icon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="80px" height="40px" class="svg-pin" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="11" y="16" width="2" height="18" rx="1"></rect>
        <filter x="-375.0%" y="-41.7%" width="850.0%" height="183.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <radialGradient cx="33.972168%" cy="30.2246094%" fx="33.972168%" fy="30.2246094%" r="96.0639357%" id="radialGradient-${identifier}">
            <stop stop-color="${color1}" offset="0%"></stop>
            <stop stop-color="${color2}" offset="100%"></stop>
        </radialGradient>
        <circle id="path-4" cx="12" cy="14" r="8"></circle>
        <filter x="-46.9%" y="-46.9%" width="193.8%" height="193.8%" filterUnits="objectBoundingBox" id="filter-5">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Pin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="#000000" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Oval">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
            <use fill="url(#radialGradient-${identifier})" fill-rule="evenodd" xlink:href="#path-4"></use>
        </g>
        <text id="text-${identifier}" font-family="Roboto-Black, Roboto" font-size="8" font-weight="700" fill="#000000">
            <tspan x="22" y="17">${number}</tspan>
        </text>
    </g>
</svg>`;
        return roadStyle ? icon : satelliteIcon;
    }

    public static getMarkerForStatus(identifier, color) {
        color = '#' + color.toUpperCase();

        const icon = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="40px" height="40px" class="svg-pin" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="11" y="16" width="2" height="18" rx="1"></rect>
        <filter x="-375.0%" y="-41.7%" width="850.0%" height="183.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-4" cx="12" cy="14" r="8"></circle>
        <filter x="-46.9%" y="-46.9%" width="193.8%" height="193.8%" filterUnits="objectBoundingBox" id="filter-5">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Pin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="#000000" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Oval">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
            <use fill="${color}" fill-rule="evenodd" xlink:href="#path-4"></use>
        </g>
        <text id="text-${identifier}" font-family="Roboto-Black, Roboto" font-size="8" font-weight="700" fill="#000000">
            <tspan x="22" y="17"></tspan>
        </text>
    </g>
</svg>`;
        return icon;
    }

    public static getLineMarker(identifier, number, color1, color2, roadStyle: boolean) {
        color1 = '#' + color1.toUpperCase();
        color2 = '#' + color2.toUpperCase();
        const satelliteIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="11" y="16" width="2" height="18" rx="1"></rect>
        <filter x="-375.0%" y="-41.7%" width="850.0%" height="183.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <radialGradient cx="33.972168%" cy="30.2246094%" fx="33.972168%" fy="30.2246094%" r="96.0639357%" id="radialGradient-${identifier}">
            <stop stop-color="${color1}" offset="0%"></stop>
            <stop stop-color="${color2}" offset="100%"></stop>
        </radialGradient>
        <circle id="path-4" cx="12" cy="14" r="8"></circle>
        <filter x="-46.9%" y="-46.9%" width="193.8%" height="193.8%" filterUnits="objectBoundingBox" id="filter-5">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <text id="text-${identifier}" font-family="Roboto-Black, Roboto" font-size="8" font-weight="700" fill="#FFFFFF">
            <tspan x="22" y="17">${number}</tspan>
        </text>
        <filter x="-60.7%" y="-72.2%" width="221.4%" height="288.9%" filterUnits="objectBoundingBox" id="filter-7">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Pin-sat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Oval">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
            <use fill="url(#radialGradient-${identifier})" fill-rule="evenodd" xlink:href="#path-4"></use>
        </g>
        <g id="123" fill="#FFFFFF" fill-opacity="1">
            <use filter="url(#filter-7)" xlink:href="#text-${identifier}"></use>
            <use xlink:href="#text-${identifier}"></use>
        </g>
    </g>
</svg>`;

        const icon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <filter x="-375.0%" y="-41.7%" width="850.0%" height="183.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <radialGradient cx="33.972168%" cy="30.2246094%" fx="33.972168%" fy="30.2246094%" r="96.0639357%" id="radialGradient-${identifier}">
            <stop stop-color="${color1}" offset="0%"></stop>
            <stop stop-color="${color2}" offset="100%"></stop>
        </radialGradient>
        <circle id="path-4" cx="12" cy="14" r="8"></circle>
        <filter x="-46.9%" y="-46.9%" width="193.8%" height="193.8%" filterUnits="objectBoundingBox" id="filter-5">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Pin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="#000000" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Oval">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
            <use fill="url(#radialGradient-${identifier})" fill-rule="evenodd" xlink:href="#path-4"></use>
        </g>
        <text id="text-${identifier}" font-family="Roboto-Black, Roboto, Sans-Serif, Arial" font-size="8" font-weight="700" fill="#000000">
            <tspan x="22" y="17">${number}</tspan>
        </text>
    </g>
</svg>`;
        return roadStyle ? icon : satelliteIcon;
    }

    public static saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: Settings.EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + Settings.EXCEL_EXTENSION);
    }

    private static isLocalHost() {
        return !LocalStorage.useTestAPI && !window['cordova'] && (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '::1' || location.hostname === 'vvr.localhost');
    }

    private static isTest() {
        return location.hostname === 'vvr-test.codalt.com' || location.hostname === 'vvr-test-old-frontend.codalt.com' || LocalStorage.useTestAPI;
    }

}
