import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ApiService} from './services/api/api.service';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './auth/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './home/home.component';
import {RequestRestorePasswordComponent} from './auth/request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from './auth/restore-password/restore-password.component';
import {UsersOverviewComponent} from './users/users-overview/users-overview.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserService} from './services/user/user.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {CustomerAreaService} from './services/consumer-area/customer-area.service';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {ConfirmDialogComponent} from './services/confirm-dialog-service/confirm-dialog/confirm-dialog.component';
import {PushpinOverviewComponent} from './pushpin-overview/pushpin-overview.component';
import {PushpinListComponent} from './pushpin-overview/pushpin-list/pushpin-list.component';
import {PushpinMapComponent} from './pushpin-overview/pushpin-map/pushpin-map.component';
import {AngularBingMapsComponent} from './pushpin-overview/pushpin-map/angular-bing-maps/angular-bing-maps.component';
import {PushpinDetailComponent} from './pushpin-detail/pushpin-detail.component';
import {PushpinPartDetailComponent} from './pushpin-detail/pushpin-part-detail/pushpin-part-detail.component';
import {PushpinPartCreationWizardComponent} from './pushpin-detail/decoration-selector-dialog/pushpin-part-creation-wizard.component';
import {VvrIconComponent} from './vvr-icon/vvr-icon.component';
import {ResetComponent} from './reset/reset.component';
import {LocationService} from './pushpin-detail/location-selector-dialog/location.service';
import {LocationSelectorDialogComponent} from './pushpin-detail/location-selector-dialog/location-selector-dialog.component';
import {CreatePushpinDialogComponent} from './pushpin-detail/pushpin-type-selection-dialog/create-pushpin-dialog.component';
import {DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import {CalculatingLengthDialogComponent} from './pushpin-detail/calculating-length-dialog/calculating-length-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {PushpinFilterComponent} from './pushpin-overview/pushpin-filter/pushpin-filter.component';
import {CROWIndicatorComponent} from './pushpin-detail/crowindicator/crowindicator.component';
import {SettingsComponent} from './settings/settings.component';
import {SafeHtml} from './pipes/safe-html.pipe';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {SafeBgUrlPipe} from './pipes/safe-bg-url.pipe';
import {CameraService} from './camera.service';
import {CordovaService} from './cordova.service';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {PushpinPartImageViewerDialogComponent} from './pushpin-detail/pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component';
import {GpsService} from './gps.service';
import {ManualAreasComponent} from './pushpin-overview/pushpin-map/manual-areas/manual-areas.component';
import {PushpinPartHistoryComponent} from './pushpin-detail/pushpin-part-history/pushpin-part-history.component';
import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';
import {DecorationsOverviewComponent} from './decorations-overview/decorations-overview.component';
import {DecorationDetailComponent} from './decorations-overview/decoration-detail/decoration-detail.component';
import {PricesComponent} from './prices/prices.component';
import {PushpinSelectionComponent} from './pushpin-overview/pushpin-selection/pushpin-selection.component';
import {LoadingComponent} from './loading/loading.component';
import {ExtraCostsOverviewComponent} from './extra-costs-overview/extra-costs-overview.component';
import {ExtraCostDetailComponent} from './extra-costs-overview/extra-cost-detail/extra-cost-detail.component';
import {ExtraCostsDialogComponent} from './pushpin-overview/pushpin-selection/extra-costs-dialog/extra-costs-dialog.component';
import {DocumentComponent} from './document/document.component';
import {ImportComponent} from './import/import.component';
import {LibraryComponent} from './library/library.component';
import {MatTreeModule} from '@angular/material/tree';
import {AddChapterComponent} from './library/add-chapter/add-chapter.component';
import {PdfViewerDialogComponent} from './library/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CdkDropList, DragDropModule} from '@angular/cdk/drag-drop';
import {RenameChapterComponent} from './library/rename-chapter/rename-chapter.component';
import {PriceLogDialogComponent} from './prices/price-log-dialog/price-log-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {PushpinExportComponent} from './pushpin-overview/pushpin-export/pushpin-export.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ProfileComponent} from './profile/profile.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CustomerAreaOverviewComponent} from './customer-areas/customer-area-overview/customer-area-overview.component';
import {CustomerAreaEditComponent} from './customer-areas/customer-area-edit/customer-area-edit.component';
import {CustomerSelectionComponent} from './customer-selection/customer-selection.component';
import {ManagementComponent} from './home/management/management.component';
import {LMRAComponent} from './lmra/lmra.component';
import {MatRadioModule} from '@angular/material/radio';
import {IncidentRegistrationComponent} from './incident-registration/incident-registration.component';
import {EditIncidentComponent} from './incident-registration/edit-incident/edit-incident.component';
import {SortingIconComponent} from './sorting-icon.component';
import {NewIncidentDialogComponent} from './incident-registration/new-incident-dialog/new-incident-dialog.component';
import {IncidentCommentComponent} from './incident-registration/incident-comment/incident-comment.component';
import {AcceptWorkComponent} from './accept-work/accept-work.component';
import {LogoutComponent} from './auth/logout/logout.component';
import {AuthenticationService} from './services/auth/authentication.service';
import {AuthorisationService} from './services/auth/authorisation.service';
import {CodaltInterceptor} from './codalt.interceptor';
import {UserFieldDialogComponent} from './auth/auth-roles/user-field-dialog/user-field-dialog.component';
import {AuthRolesComponent} from './auth/auth-roles/auth-roles.component';
import {CodaltComponent} from './codalt.component';
import {RepairsComponent} from './repairs/repairs.component';
import {EditRepairComponent} from './repairs/edit-repair/edit-repair.component';
import {RepairCommentComponent} from './repairs/repair-comment/repair-comment.component';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleDetailComponent} from './articles/article-detail/article-detail.component';
import {ImageViewerDialogComponent} from './image-viewer-dialog/image-viewer-dialog.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {CodaltImageComponent} from './codalt-image/codalt-image.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ArticleService} from './services/article.service';
import {SafeSrcPipe} from './pipes/safe-src.pipe';
import {CanDeactivateForm} from './can-deactivate-form';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FileManagerComponent} from './file-manager/file-manager.component';
import {FileSelectionDialogComponent} from './file-manager/file-selection-dialog/file-selection-dialog.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {FileService} from './services/file.service';
import {ChooseFileSourceComponent} from './file-manager/choose-file-source/choose-file-source.component';
import {MatListModule} from '@angular/material/list';
import {FileAddItemComponent} from './file-manager/file-add-item-dialog/file-add-item.component';
import {SimpleTinyComponent} from './simple-tiny/simple-tiny.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {PricesEditComponent} from './prices/prices-edit/prices-edit.component';
import {QRCodeModule} from 'angularx-qrcode';
import {TwoFactorComponent} from './auth/two-factor/two-factor.component';
import {TwoFactorSetupComponent} from './auth/two-factor-setup/two-factor-setup.component';
import {ChooseInspectionComponent} from './choose-inspection/choose-inspection.component';
import {NewInspectionDialogComponent} from './choose-inspection/new-inspection-dialog/new-inspection-dialog.component';
import {ChooseInspectionDialogComponent} from './choose-inspection/choose-inspection-dialog/choose-inspection-dialog.component';
import {PushpinChangesDialogComponent} from './pushpin-overview/pushpin-selection/pushpin-changes-dialog/pushpin-changes-dialog.component';
import {PushpinMoveComponent} from './pushpin-overview/pushpin-move/pushpin-move.component';
import {ByIdPipe} from './pipes/by-id.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {TailChargeDialogComponent} from './pushpin-overview/pushpin-selection/tail-charge-dialog/tail-charge-dialog.component';
import {DecimalInputDirective} from './directives/decimal-input.directive';
import {ApiUrlService} from './services/api-url.service';
import {PpeManagementComponent} from './ppe-management/ppe-management.component';
import {PpeEquipmentEditComponent} from './ppe-management/ppe-equipment-edit/ppe-equipment-edit.component';
import {PpeRequestComponent} from './ppe-request/ppe-request.component';
import {PpeRequestDetailComponent} from './ppe-request/ppe-request-detail/ppe-request-detail.component';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {PinchZoomModule} from 'ngx-pinch-zoom';
import {ChecklistsComponent} from './checklists/checklists.component';
import {ChecklistEditComponent} from './checklists/checklist-edit/checklist-edit.component';
import {ChecklistFillInComponent} from './checklists/checklist-fill-in/checklist-fill-in.component';
import {ChecklistOverviewComponent} from './checklists/checklist-overview/checklist-overview.component';
import {ChecklistChooseDialogComponent} from './checklists/checklist-overview/checklist-choose-dialog/checklist-choose-dialog.component';
import {ChecklistPDFComponent} from './checklists/checklist-pdf/checklist-pdf.component';
import {PriceIndexationDialogComponent} from './prices/price-indexation-dialog/price-indexation-dialog.component';
import {UserAuthLogComponent} from './auth/user-auth-log/user-auth-log.component';
import {DeclarationManagementComponent} from './declaration-management/declaration-management.component';
import {DeclarationTypeEditComponent} from './declaration-management/declaration-type-edit/declaration-type-edit.component';
import {DeclarationRequestComponent} from './declaration-request/declaration-request.component';
import {DeclarationRequestDetailComponent} from './declaration-request/declaration-request-detail/declaration-request-detail.component';
import {DeclarationReportComponent} from './declaration-management/declaration-report/declaration-report.component';
import {HasFeaturePipe} from './pipes/has-feature.pipe';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MoldRequestComponent} from './mold-request/mold-request.component';
import {MoldRequestDetailComponent} from './mold-request/mold-request-detail/mold-request-detail.component';
import {MoldManagementComponent} from './mold-management/mold-management.component';
import {MoldMaterialEditComponent} from './mold-management/mold-material-edit/mold-material-edit.component';
import {SmallMaterialRequestComponent} from './small-material-request/small-material-request.component';
import {SmallMaterialManagementComponent} from './small-material-management/small-material-management.component';
import {SmallMaterialEditComponent} from './small-material-management/small-material-edit/small-material-edit.component';
import {SmallMaterialRequestDetailComponent} from './small-material-request/small-material-request-detail/small-material-request-detail.component';
import {DecorationGroupOverviewComponent} from './decorations-overview/decoration-group-overview/decoration-group-overview.component';
import {LibraryTreeComponent} from './library/library-tree/library-tree.component';
import {DataExportComponent} from './data-export/data-export.component';
import {CustomErrorHandlerService} from './custom-error-handler-service';
import {MatMenuModule} from '@angular/material/menu';
import {DrawOnImageComponent} from './draw-on-image/draw-on-image.component';
import {DrawOnImageDialogComponent} from './pushpin-detail/draw-on-image-dialog/draw-on-image-dialog.component';
import {TotalPricePushpinPartsPipe} from './dashboard/total-price-pushpin-parts.pipe';
import { TotalPriceExtraCostsPipe } from './dashboard/total-price-extra-costs.pipe';
import {TotalSurfacePushpinPartsPipe} from './dashboard/total-surface-pushpin-parts.pipe';
import {TotalCountPushpinPartsPipe} from './dashboard/total-count-pushpin-parts.pipe';
import { PercentPricePipe } from './dashboard/percent-price.pipe';
import { PercentSurfacePipe } from './dashboard/percent-surface.pipe';
import { PercentCountPipe } from './dashboard/percent-count.pipe';
import { ShapeMapComponent } from './shape-map/shape-map.component';

registerLocaleData(localeNL, 'nl');

export function initializeAppUrl(appInitService: ApiUrlService) {
    return (): Promise<any> => {
        return appInitService.Init();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        RequestRestorePasswordComponent,
        RestorePasswordComponent,
        UsersOverviewComponent,
        UserEditComponent,
        ConfirmDialogComponent,
        PushpinOverviewComponent,
        PushpinListComponent,
        PushpinMapComponent,
        AngularBingMapsComponent,
        PushpinDetailComponent,
        PushpinPartDetailComponent,
        PushpinPartCreationWizardComponent,
        PushpinPartDetailComponent,
        VvrIconComponent,
        ResetComponent,
        LocationSelectorDialogComponent,
        CreatePushpinDialogComponent,
        CalculatingLengthDialogComponent,
        CROWIndicatorComponent,
        PushpinFilterComponent,
        SettingsComponent,
        SafeHtml,
        Nl2brPipe,
        SafeBgUrlPipe,
        PushpinPartImageViewerDialogComponent,
        ManualAreasComponent,
        PushpinPartHistoryComponent,
        DecorationsOverviewComponent,
        DecorationDetailComponent,
        PricesComponent,
        PushpinSelectionComponent,
        LoadingComponent,
        ExtraCostsOverviewComponent,
        ExtraCostDetailComponent,
        ExtraCostsDialogComponent,
        DocumentComponent,
        ImportComponent,
        LibraryComponent,
        AddChapterComponent,
        PdfViewerDialogComponent,
        RenameChapterComponent,
        PriceLogDialogComponent,
        PushpinExportComponent,
        DashboardComponent,
        ProfileComponent,
        CustomerAreaOverviewComponent,
        CustomerAreaEditComponent,
        CustomerSelectionComponent,
        ManagementComponent,
        LMRAComponent,
        IncidentRegistrationComponent,
        EditIncidentComponent,
        SortingIconComponent,
        NewIncidentDialogComponent,
        IncidentCommentComponent,
        AcceptWorkComponent,
        LogoutComponent,
        UserFieldDialogComponent,
        AuthRolesComponent,
        CodaltComponent,
        RepairsComponent,
        EditRepairComponent,
        RepairCommentComponent,
        ArticleEditComponent,
        ArticlesComponent,
        ArticleDetailComponent,
        ImageViewerDialogComponent,
        CodaltImageComponent,
        FileManagerComponent,
        FileSelectionDialogComponent,
        ChooseFileSourceComponent,
        FileAddItemComponent,
        SimpleTinyComponent,
        SafeSrcPipe,
        PricesEditComponent,
        TwoFactorComponent,
        TwoFactorSetupComponent,
        ChooseInspectionComponent,
        NewInspectionDialogComponent,
        ChooseInspectionDialogComponent,
        PushpinChangesDialogComponent,
        PushpinMoveComponent,
        ByIdPipe,
        FilterPipe,
        TailChargeDialogComponent,
        DecimalInputDirective,
        PpeManagementComponent,
        PpeEquipmentEditComponent,
        PpeRequestComponent,
        PpeRequestDetailComponent,
        ChecklistsComponent,
        ChecklistEditComponent,
        ChecklistFillInComponent,
        ChecklistOverviewComponent,
        ChecklistChooseDialogComponent,
        ChecklistPDFComponent,
        PriceIndexationDialogComponent,
        UserAuthLogComponent,
        DeclarationManagementComponent,
        DeclarationTypeEditComponent,
        DeclarationRequestComponent,
        DeclarationRequestDetailComponent,
        DeclarationReportComponent,
        HasFeaturePipe,
        MoldRequestComponent,
        MoldRequestDetailComponent,
        MoldManagementComponent,
        MoldMaterialEditComponent,
        SmallMaterialRequestComponent,
        SmallMaterialRequestDetailComponent,
        SmallMaterialManagementComponent,
        SmallMaterialEditComponent,
        DecorationGroupOverviewComponent,
        LibraryTreeComponent,
        DataExportComponent,
        DrawOnImageComponent,
        DrawOnImageDialogComponent,
        TotalPricePushpinPartsPipe,
        TotalPriceExtraCostsPipe,
        TotalSurfacePushpinPartsPipe,
        TotalCountPushpinPartsPipe,
        PercentPricePipe,
        PercentSurfacePipe,
        PercentCountPipe,
        ShapeMapComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatIconModule,
        MatBadgeModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        SwiperModule,
        ScrollingModule,
        MatTabsModule,
        MatTreeModule,
        MatSlideToggleModule,
        DragDropModule,
        MatTooltipModule,
        MatSliderModule,
        HammerModule,
        MatRadioModule,
        MatRippleModule,
        VirtualScrollerModule,
        AngularSvgIconModule.forRoot(),
        MatNativeDateModule,
        MatDatepickerModule,
        MatListModule,
        MatExpansionModule,
        QRCodeModule,
        PinchZoomModule,
        ClipboardModule,
        MatMenuModule
    ],
    providers: [
        ApiService,
        AuthenticationService,
        AuthorisationService,
        UserService,
        ArticleService,
        CustomerAreaService,
        ConfirmDialogService,
        LocationService,
        CameraService,
        CordovaService,
        FileService,
        GpsService,
        DatePipe,
        DecimalPipe,
        MatBottomSheet,
        CdkDropList,
        CanDeactivateForm,
        CdkVirtualScrollViewport,
        FirebaseX,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: LOCALE_ID, useValue: 'nl'},
        {provide: HTTP_INTERCEPTORS, useClass: CodaltInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: initializeAppUrl, deps: [ApiUrlService], multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
